import Link from '../../components/Link';
import Text from '../../components/Text';
import { styled } from '../../theme/stitches.config';
import { CARD_IMAGE_DIMENSIONS } from './EventImage/dimensions';

export const CARD_HEIGHT = 300;
export const CARD_WIDTH = CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH;

export const StyledCardContainer = styled('div', {
  borderRadius: 8,
  '@sm': {
    width: CARD_IMAGE_DIMENSIONS.SM.CONTAINER_WIDTH,
  },
  '@md': {
    width: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
  },
  '@lg': {
    width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
  },
});

export const StyledCardContent = styled('div', {
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  cursor: 'pointer',
  height: CARD_HEIGHT - CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
  padding: 8,
  '&:hover': {
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
});

export const StyledDescription = styled(Text, {
  marginTop: 8,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '@supports (-webkit-line-clamp: 2)': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
});

export const StyledLink = styled(Link, {
  ':hover': {
    color: 'initial',
  },
});
