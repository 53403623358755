import { styled } from '@goodfynd/react-web.theme';

import FilterMenu from '../../../components/FilterMenu';

export const StyledContainer = styled('section', {
  marginTop: 32,
});

export const StyledDayMenu = styled(FilterMenu, {
  '&.MuiButton-root': {
    border: 'none !important',
    marginTop: 0,
    paddingLeft: '0 !important',
    '> p': {
      color: '$Neutral13',
      fontSizeRem: 26,
      fontWeight: 800,
      lineHeightRem: 32,
      '+ span': {
        marginLeft: 0,
      },
    },
  },
});

export const StyledLoadMoreContainer = styled('section', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem 0',
  '@mdMax': {
    padding: 0,
  },
});
