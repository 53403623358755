import { Text } from '../';
import { styled } from '../../theme/stitches.config';

const BORDER_RADIUS = 18;
export const StyledButton = styled('button', {
  backgroundColor: '$GreenBrand',
  border: 'none',
  cursor: 'pointer',
  fontSizeRem: 14,
  fontWeight: 800,
  lineHeightRem: 21,
  outline: 'none',
  padding: '5px 20px 6px',
  '&:first-child': {
    borderBottomLeftRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
  },
  '&:last-child': {
    borderBottomRightRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$White',
        borderRadius: BORDER_RADIUS,
      },
    },
  },
});

export const StyledText = styled(Text, {
  color: '$White',
  variants: {
    selected: {
      true: {
        color: '$GreenBrand',
      },
    },
  },
});

export const StyledToggleContainer = styled('aside', {
  backgroundColor: '$GreenBrand',
  borderRadius: BORDER_RADIUS,
  border: '2px solid $GreenBrand',
  boxShadow:
    '0px 2px 10px rgba(0, 0, 0, 0.05), 0px 1px 6px rgba(0, 0, 0, 0.05)',
  cursor: 'pointer',
  width: 'fit-content',
});
