import React from 'react';

import Grid from '@mui/material/Grid';

import CmsPageContent from '../../components/cms/CmsPageContent';
import strings from '../../config/strings';
import { useApp } from '../../context/app-context';
import { useSearch } from '../../context/search-context';
import CardsContainer from '../../context/search-context/SearchResults/CardsContainer';
import EventCard from './EventCard';
import { StyledFlexCenter } from '../../components/styles';

export function EventResults() {
  const { isLoggedIn } = useApp();
  const {
    context,
    eventSearch: { isFetched, pages, total },
  } = useSearch();

  if (isFetched && !total) {
    return <CmsPageContent slug="consumer-search-no-events" />;
  }

  if (isLoggedIn) {
    return (
      <CardsContainer css={{ '&.MuiGrid-root': { paddingTop: 48 } }}>
        {pages
          ?.map((page) => page.results)
          .map((items) =>
            items?.map((item, index) => (
              <Grid key={item.id} item xs={12} md={6} lg={4}>
                <EventCard
                  data={item}
                  gtmData={{
                    list_id: context,
                    list_name: strings.labels.events,
                  }}
                  index={index}
                  key={item.id}
                />
              </Grid>
            ))
          )}
      </CardsContainer>
    );
  }

  return (
    <StyledFlexCenter css={{ flexDirection: 'column', marginTop: 48 }}>
      <CmsPageContent slug="consumer-event-login" />
    </StyledFlexCenter>
  );
}
