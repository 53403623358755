import { convertDistance, getPreciseDistance } from 'geolib';
import React from 'react';

import { Text } from '@goodfynd/react-web.typography.text';
import { getTimeDisplay } from '@goodfynd/react-web.utils.date-util';
import { gtmUtil } from '@goodfynd/react-web.utils.gtm-util';

import { Icon } from '../../components/Icon';
import config from '../../config';
import strings from '../../config/strings';
import * as storageUtil from '../../utils/storage-util';
import {
  StyledCardContainer,
  StyledCardContent,
  StyledLink,
  StyledProductTypes,
  StyledQuickInfo,
} from './styles';
import VendorImages from './VendorImages';

import type { GtmListEvent } from '../../types/gtm';
import type { VendorCardProps } from './types';
export function VendorCard({
  data,
  gtmData,
  index,
  showSchedule = true,
  track = true,
}: VendorCardProps) {
  const productTypeNames = (data.productTypes || data.vendorProductTypes)?.map(
    (productType) => productType.name
  );
  const hasProductTypes = !!productTypeNames?.[0];

  const userLocation = storageUtil.local.get<ItemLocation>(
    config.storageKeys.userLocation
  );

  const likes = data.likes || data.vendorLikes;
  return (
    <StyledLink
      href={
        config.app.mode.isProduction
          ? data.vendorUrl || data.url
          : `${config.env.APP_HOST}/vendors/${
              data.vendorNameId || data.vendorId || data.id
            }`
      }
      onClick={() => {
        track &&
          gtmUtil.pushEvent({
            event: 'fynd_select_vendor',
            data: {
              index,
              id: data.id,
              name: data.name || data.vendorName,
              ...gtmData,
            },
            ...gtmData,
          } as GtmListEvent);
      }}
    >
      <StyledCardContainer>
        <VendorImages
          image={data.image || data.vendorImage}
          productImages={data.topProductImages}
        />

        <StyledCardContent>
          <Text as="h2" type="subh2">
            {data.name || data.vendorName}
          </Text>

          <StyledQuickInfo>
            <Icon
              color="Neutral09"
              name={likes ? 'heart_filled' : 'heart'}
              size={18}
            />
            {likes > 0 && <Text type="body2">&nbsp;({likes})</Text>}
            {hasProductTypes && (
              <StyledProductTypes type="body2">
                {config.unicode.centerDotPadded}
                {productTypeNames.join(', ')}
              </StyledProductTypes>
            )}
          </StyledQuickInfo>

          <StyledQuickInfo css={{ marginTop: 4 }}>
            {!!data.scheduleDay ? (
              <Text type="body2">
                {strings.labels.open}&nbsp;
                {getTimeDisplay(data.scheduleStart, false).toLowerCase()}-
                {getTimeDisplay(data.scheduleEnd, false).toLowerCase()}
                {userLocation &&
                  data.scheduleLocation &&
                  `${config.unicode.centerDotPadded}${convertDistance(
                    getPreciseDistance(
                      userLocation, // TODO: Store location in root store
                      data.scheduleLocation.coordinates
                    ),
                    'mi'
                  ).toFixed(1)} mi.`}
              </Text>
            ) : (
              showSchedule && (
                <Text type="body2">{strings.labels.noEvents}</Text>
              )
            )}
          </StyledQuickInfo>

          {!!data.scheduleAddress && (
            <StyledQuickInfo>
              <Text truncate={1} type="body2">
                {data.scheduleAddress}
              </Text>
            </StyledQuickInfo>
          )}
        </StyledCardContent>
      </StyledCardContainer>
    </StyledLink>
  );
}
