import React from 'react';

import images from '../../../config/images';
import { numberUtil } from '../../../utils';
import { StyledContainer } from './styles';
import { EventImageProps } from './types';

export default function EventImage({
  image,
  location = 'card',
}: EventImageProps) {
  let imageUrl = image;
  if (!imageUrl) {
    const imageKeys = Object.keys(images.fallbacks.event);
    const imageKey = imageKeys[
      numberUtil.random(0, imageKeys.length - 1)
    ] as keyof typeof images.fallbacks.event;
    imageUrl = images.fallbacks.event[imageKey];
  }

  return (
    <StyledContainer
      css={{
        backgroundImage: `url(${imageUrl})`,
      }}
      location={location}
    />
  );
}
