import React from 'react';

import images from '../../../config/images';
import { StyledContainer } from './styles';
import { ProductImageProps } from './types';

export default function ProductImage({
  image = images.fallbacks.product,
  location = 'card',
}: ProductImageProps) {
  return (
    <StyledContainer
      css={{
        backgroundImage: `url(${image})`,
      }}
      location={location}
    />
  );
}
