import React from 'react';
import { useQueryClient } from 'react-query';

import { useMobileSize } from '@goodfynd/react-web.utils.base-util';
import InputAdornment from '@mui/material/InputAdornment';
import NoSsr from '@mui/material/NoSsr';

import { Icon } from '../../../components/Icon';
import config from '../../../config';
import strings from '../../../config/strings';
import useGeoSearch from '../../../hooks/useGeoSearch';
import {
  StyledForm,
  StyledInputsContainer,
  StyledSearchButton,
  StyledSearchTextField,
} from './styles';

import type { SearchInputsProps } from './types';
export default function SearchInputs({
  firstInputPosition,
  page = 'home',
  phrase,
  refetchAll,
  secondInputPosition,
  updatePhrase,
}: SearchInputsProps) {
  const geoSearch = useGeoSearch();
  const queryClient = useQueryClient();
  const isMobile = useMobileSize();
  const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    queryClient.setQueryData(config.queryKeys.searchEvents, undefined);
    queryClient.setQueryData(config.queryKeys.searchProducts, undefined);
    queryClient.setQueryData(config.queryKeys.searchVendors, undefined);
    refetchAll();
  };

  const handleSearchTermChange = (e: HtmlInputChangeEvent) => {
    updatePhrase(e.target.value);
  };

  return (
    <NoSsr>
      <StyledForm>
        <StyledInputsContainer>
          <StyledSearchTextField
            id="search"
            onChange={handleSearchTermChange}
            placeholder={strings.labels.homeSearchPlaceholder}
            position={
              firstInputPosition ?? isMobile
                ? 'input-top'
                : undefined || (page === 'home' ? 'input-top' : 'input-left')
            }
            value={phrase}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="search" />
                </InputAdornment>
              ),
            }}
          />

          {geoSearch.renderInput(
            page,
            secondInputPosition ?? isMobile ? 'input-bottom' : undefined
          )}

          <StyledSearchButton onClick={handleSearch} size="md" type="submit">
            {strings.labels.letsEat}
          </StyledSearchButton>
        </StyledInputsContainer>
      </StyledForm>
    </NoSsr>
  );
}
