import { styled } from '@goodfynd/react-web.theme';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const StyledCardsContainer = styled(Grid, {
  '&.MuiGrid-root': {
    paddingTop: 24,
  },
});

export const StyledContextSwitcherContainer = styled('div', {
  backgroundColor: '$GreenBrand',
  borderRadius: 18,
});

export const StyledLoadMoreContainer = styled('section', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 32,
});

export const StyledStackContainer = styled(Stack, {
  borderRadius: 8,
  marginTop: 40,
  overflow: 'hidden',
  '@sm': {
    flex: '0 1 1',
    marginTop: 32,
  },
  '@md': {
    flex: '0 1 calc(33% - 1em)',
  },
});
