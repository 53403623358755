import React from 'react';

import { StyledCardsContainer } from './styles';

import type { CardsContainerProps } from './types';

export default function CardsContainer({
  children,
  ...props
}: CardsContainerProps) {
  return (
    <StyledCardsContainer container rowSpacing={8} spacing={4} {...props}>
      {children}
    </StyledCardsContainer>
  );
}
