export const CARD_IMAGE_DIMENSIONS = {
  CONTAINER_HEIGHT: 152,
  CONTAINER_WIDTH: 380,
  LEFT_WIDTH: `${(254 * 100) / 380}%`,
  RIGHT_HEIGHT: 75,
  RIGHT_WIDTH: `${(122 * 100) / 380}%`,
  MD: {
    CONTAINER_HEIGHT: 128,
    CONTAINER_WIDTH: 340,
    LEFT_WIDTH: `${(216 * 100) / 340}%`,
    RIGHT_HEIGHT: 63,
    RIGHT_WIDTH: `${(118 * 100) / 340}%`,
  },
  SM: {
    CONTAINER_HEIGHT: 128,
    CONTAINER_WIDTH: 340,
    LEFT_WIDTH: `${(208 * 100) / 340}%`,
    RIGHT_HEIGHT: 63,
    RIGHT_WIDTH: `${(118 * 100) / 340}%`,
  },
};

export const DETAILS_IMAGE_DIMENSIONS = {
  CONTAINER_HEIGHT: 400,
  CONTAINER_WIDTH: 1201,
  LEFT_WIDTH: `${(819 * 100) / 1201}%`,
  RIGHT_HEIGHT: 198,
  RIGHT_WIDTH: `${(378 * 100) / 1201}%`,
  MD: {
    CONTAINER_HEIGHT: 240,
    CONTAINER_WIDTH: 583,
    LEFT_WIDTH: `${(375 * 100) / 583}%`,
    RIGHT_HEIGHT: 118,
    RIGHT_WIDTH: `${(204 * 100) / 583}%`,
  },
  SM: {
    CONTAINER_HEIGHT: 224,
  },
};
