import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import config from '../../config';
import strings from '../../config/strings';
import { useLocation } from '../../context/location-context';
import { useProductApi } from '../api';
import * as gtmUtil from '../../utils/gtm-util';

import type { QueryFunctionContext, QueryKey } from 'react-query';

import type { ProductSearchResponse } from '../../services/api/types';
import type { SearchRequest } from '../../types/search';
import type { ProductSearchOptions } from './types';
import type { GtmItemListView } from '../../types/gtm';
import type { SearchContextResults } from '../../context/search-context/types';

export default function useProductSearch({
  context,
  phrase,
  rows = 0,
}: ProductSearchOptions): SearchContextResults<ProductSearchResponse> {
  const api = useProductApi();
  const [lastRequest, updateLastRequest] = useState<SearchRequest>({
    page: 1,
    phrase,
    rows,
  });
  const currentPage = useMemo(() => lastRequest.page || 1, [lastRequest.page]);

  const { position } = useLocation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<ProductSearchResponse, unknown, ProductSearchResponse>(
    [
      config.queryKeys.searchProducts,
      `${position?.latitude},${position?.longitude}`,
    ],
    ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const request: SearchRequest = {
        page: pageParam || 1,
        query: phrase,
        rows,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }

      updateLastRequest(request);
      return api.search(request);
    },
    {
      getNextPageParam: (prevResults: ProductSearchResponse) =>
        prevResults.hasMore ? prevResults.page + 1 : undefined,
    }
  );

  const total = useMemo(
    () => data?.pages?.[currentPage - 1]?.total || 0,
    [currentPage, data?.pages]
  );

  useEffect(() => {
    const results = data?.pages[currentPage - 1]?.results || [];
    if (
      results.length > 0 &&
      currentPage >= 1 &&
      isFetched &&
      rows > 0 &&
      total > 0
    ) {
      if (context === 'items') {
        gtmUtil.pushEvent({
          event: 'view_search_results',
          page_location:
            window.location.pathname + (window.location.search || ''),
          search_term: phrase,
        });
      }

      const gtmData: GtmItemListView = {
        event: 'view_item_list',
        item_list_id: context,
        item_list_name: strings.labels.products,
        page: currentPage,
        ecommerce: {
          items: results.map((item, index) => ({
            index,
            item_id: item.id,
            item_name: item.productName,
            price: item.productPrice,
            quantity: 1,
            vendor: item.vendorName,
            vendor_id: item.vendorId,
          })),
        },
      };
      gtmUtil.pushEvent(gtmData);
    }
  }, [context, currentPage, data, isFetched, phrase, rows, total]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pages: data?.pages,
    refetch,
    total,
  };
}
