import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import type { ReactElement} from 'react';
import React, { useCallback, useMemo } from 'react';

import { styled } from '@goodfynd/react-web.theme';

import { Container, Layout } from '../../../components';
import ToggleButtons from '../../../components/ToggleButtons';
import type { ToggleItem } from '../../../components/ToggleButtons/types';
import strings from '../../../config/strings';
import { SearchProvider, useSearch, useSearchDispatch } from '../../../context/search-context';
import SearchInputs from '../../../context/search-context/SearchInputs';
import { SearchResults } from '../../../context/search-context/SearchResults';
import { setPageTitle } from '../../../utils/base';
import type { NextPageWithLayout } from '../../_app';

import type {
  SearchContextResults,
  SearchContextType,
} from '../../../context/search-context/types';
const CONTEXT_DICTIONARY: Dictionary<SearchContextType, string> = {
  events: strings.labels.events,
  items: strings.labels.items,
  vendors: strings.labels.trucks,
};

const StyledContainer = styled(Container, {
  paddingBottom: 40,
  paddingTop: 68,
  '@sm': {
    paddingTop: 178,
  },
});

const StyledSearchControls = styled('section', {
  marginTop: 24,
  '@lg': {
    alignItems: 'center',
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',
  },
});

const Search: NextPageWithLayout = () => {
  const { phrase, eventSearch, productSearch, vendorSearch, context } =
    useSearch();
  const { refetch, refetchAll, setContext, updateTerm } = useSearchDispatch();

  const renderContextLabel = useCallback(
    (
      contextKey: SearchContextType,
      results?: SearchContextResults<unknown>
    ) => {
      return results?.isFetching && !results.total
        ? CONTEXT_DICTIONARY[contextKey]
        : `${CONTEXT_DICTIONARY[contextKey]} (${results?.total || 0})`;
    },
    []
  );

  const options = useMemo<ToggleItem<SearchContextType>[]>(
    () => [
      {
        label: renderContextLabel('vendors', vendorSearch),
        value: 'vendors',
      },
      {
        label: renderContextLabel('items', productSearch),
        value: 'items',
      },
      {
        label: renderContextLabel('events', eventSearch),
        value: 'events',
      },
    ],
    [eventSearch, productSearch, renderContextLabel, vendorSearch]
  );

  let defaultOption: ToggleItem<SearchContextType> = options[0];
  if (context === 'vendors') {
    defaultOption = {
      label: renderContextLabel('vendors', vendorSearch),
      value: 'vendors',
    };
  } else if (context === 'items') {
    defaultOption = {
      label: renderContextLabel('items', productSearch),
      value: 'items',
    };
  } else if (context === 'events') {
    defaultOption = {
      label: renderContextLabel('events', eventSearch),
      value: 'events',
    };
  }

  return (
    <>
      <Head>
        <title>{setPageTitle('Search')}</title>
        <meta name="robots" content="noindex" />
      </Head>

      <StyledContainer>
        <SearchInputs
          page="search"
          phrase={phrase}
          refetch={refetch}
          refetchAll={refetchAll}
          updatePhrase={updateTerm}
        />

        <StyledSearchControls>
          <ToggleButtons<SearchContextType>
            onChange={(newContext: SearchContextType) => setContext(newContext)}
            options={options}
            defaultOption={defaultOption}
          />
        </StyledSearchControls>

        <SearchResults />
      </StyledContainer>
    </>
  );
};

Search.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout headerShadow={false}>
      <SearchProvider>{page}</SearchProvider>
    </Layout>
  );
};

export default observer(Search);
