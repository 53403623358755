import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import config from '../../config';
import strings from '../../config/strings';
import { useLocation } from '../../context/location-context';
import { SearchContextResults } from '../../context/search-context/types';
import { useVendorApi } from '../../hooks/api';
import { gtmUtil } from '../../utils';
import useOpenVendorsSearch from './useOpenVendorsSearch';

import type { GtmVendorListView } from '../../types/gtm';
import type { QueryFunctionContext, QueryKey } from 'react-query';

import type { VendorSearchResponse } from '../../services/api/types';
import type { SearchRequest } from '../../types/search';
import type { VendorSearchOptions } from './types';

export default function useVendorSearch({
  context,
  phrase,
  rows = 0,
}: VendorSearchOptions): SearchContextResults<VendorSearchResponse> & {
  openVendorsSearch: ReturnType<typeof useOpenVendorsSearch>;
} {
  const api = useVendorApi();
  const { position } = useLocation();

  const [total, setTotal] = useState(0);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<VendorSearchResponse, unknown, VendorSearchResponse>(
    [
      config.queryKeys.searchVendors,
      `${position?.latitude},${position?.longitude}`,
    ],
    ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const request: SearchRequest = {
        page: pageParam || 1,
        query: phrase,
        rows,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }
      return api.search(request);
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.page + 1 : undefined,
      onSuccess: (data) => {
        const pageData = data.pages.at(-1);
        if (!pageData?.results?.[0]) {
          return;
        }

        if (rows > 0) {
          if (context === 'vendors') {
            gtmUtil.pushEvent({
              event: 'view_search_results',
              page_location:
                window.location.pathname + (window.location.search || ''),
              search_term: phrase,
            });
          }
          const gtmData: GtmVendorListView = {
            event: 'fynd_view_vendor_list',
            list_id: context,
            list_name: strings.labels.vendors,
            page: pageData.page,
            list: pageData.results.map((item, index) => ({
              index,
              vendor_id: item.id,
              vendor_name: item.name || item.vendorName,
            })),
          };
          gtmUtil.pushEvent(gtmData);
        }

        setTotal(pageData?.total);
      },
    }
  );

  const openVendorsSearch = useOpenVendorsSearch({ context, phrase, rows });

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    openVendorsSearch,
    pages: data?.pages,
    refetch,
    total,
  };
}
