import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { stringify } from 'query-string';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import config from '../../config';
import useEventSearch from '../../hooks/useEventSearch';
import useGeoSearch from '../../hooks/useGeoSearch';
import useProductSearch from '../../hooks/useProductSearch';
import useVendorSearch from '../../hooks/useVendorSearch';
import { useStores } from '../../stores';
import { useApp } from '../app-context';

import type {
  SearchContextType,
  SearchContextValue,
  SearchDispatchContextValue,
  SearchProviderProps,
  SearchStatus,
} from './types';

export const DEFAULT_SEARCH_ROWS = 24;

export const SearchContext = createContext<SearchContextValue | undefined>(
  undefined
);
export const SearchDispatchContext = createContext<
  SearchDispatchContextValue | undefined
>(undefined);

export default observer(function SearchProvider({
  children,
}: SearchProviderProps) {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useApp();

  const url = global.window && new URL(location.href);
  const router = useRouter();
  const {
    location: { position },
  } = useGeoSearch();

  const {
    searchStore: { display },
  } = useStores();

  const [context, setContext] = useState<SearchContextType>('vendors');
  const [phrase, updateTerm] = useState(url?.searchParams.get('q') || '');

  const eventSearch = useEventSearch({
    context,
    phrase,
    rows: DEFAULT_SEARCH_ROWS,
  });

  const productSearch = useProductSearch({
    context,
    phrase,
    rows: DEFAULT_SEARCH_ROWS,
  });

  const vendorSearch = useVendorSearch({
    context,
    phrase,
    rows: DEFAULT_SEARCH_ROWS,
  });

  const status = useMemo<SearchStatus>(() => {
    switch (context) {
      case 'events':
        return {
          fetchNextPage: eventSearch.fetchNextPage,
          hasNextPage: eventSearch.hasNextPage,
          isFetching: eventSearch.isFetching,
          isFetchingNextPage: eventSearch.isFetchingNextPage,
          total: eventSearch.total,
        };

      case 'items':
        return {
          fetchNextPage: productSearch.fetchNextPage,
          hasNextPage: productSearch.hasNextPage,
          isFetching: productSearch.isFetching,
          isFetchingNextPage: productSearch.isFetchingNextPage,
          total: productSearch.total,
        };

      case 'vendors':
        return {
          fetchNextPage: vendorSearch.fetchNextPage,
          hasNextPage: vendorSearch.hasNextPage,
          isFetching: vendorSearch.isFetching,
          isFetchingNextPage: vendorSearch.isFetchingNextPage,
          total: vendorSearch.total,
        };
    }
  }, [
    context,
    eventSearch.fetchNextPage,
    eventSearch.hasNextPage,
    eventSearch.isFetching,
    eventSearch.isFetchingNextPage,
    eventSearch.total,
    productSearch.fetchNextPage,
    productSearch.hasNextPage,
    productSearch.isFetching,
    productSearch.isFetchingNextPage,
    productSearch.total,
    vendorSearch.fetchNextPage,
    vendorSearch.hasNextPage,
    vendorSearch.isFetching,
    vendorSearch.isFetchingNextPage,
    vendorSearch.total,
  ]);

  const refetch = useCallback(() => {
    switch (context) {
      case 'events':
        return () => {
          queryClient.setQueryData(config.queryKeys.searchEvents, undefined);
          eventSearch.refetch();
        };

      case 'items':
        return () => {
          queryClient.setQueryData(config.queryKeys.searchProducts, undefined);
          productSearch.refetch();
        };

      case 'vendors':
        return () => {
          queryClient.setQueryData(config.queryKeys.searchVendors, undefined);
          vendorSearch.refetch();
          vendorSearch.openVendorsSearch.refetch();
        };
    }
  }, [context, eventSearch, productSearch, queryClient, vendorSearch]);

  const updateUrl = useCallback(() => {
    router.push(
      `?${stringify({
        q: phrase ? phrase : undefined,
        l: position ? `@${position.latitude},${position.longitude}` : undefined,
      })}`
    );
  }, [phrase, position, router]);

  const refetchAll = useCallback(() => {
    if (isLoggedIn) {
      eventSearch.refetch();
    }

    productSearch.refetch();
    vendorSearch.refetch();
    vendorSearch.openVendorsSearch.refetch();
    updateUrl();
  }, [eventSearch, isLoggedIn, productSearch, updateUrl, vendorSearch]);

  console.debug(
    new Date().toLocaleString(),
    '\nVendor Result Pages:\n',
    vendorSearch.pages,
    'Product Result Pages:\n',
    productSearch.pages,
    'Event Result Pages:\n',
    eventSearch.pages
  );

  return (
    <SearchContext.Provider
      value={{
        display,
        context,
        phrase,
        rows: DEFAULT_SEARCH_ROWS,
        status,
        eventSearch,
        productSearch,
        vendorSearch,
      }}
    >
      <SearchDispatchContext.Provider
        value={{
          refetch,
          refetchAll,
          setContext,
          updateTerm,
        }}
      >
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
});
