import React from 'react';

import { SearchContext, SearchDispatchContext } from './SearchContext';

export { default as SearchProvider } from './SearchContext';

export function useSearch() {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error('The useSearch hook must be used within a SearchProvider.');
  }
  return context;
}

export function useSearchDispatch() {
  const context = React.useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useSearchDispatch hook must be used within a SearchProvider.'
    );
  }
  return context;
}
