import React from 'react';

import {
    StyledContainer, StyledLeftContainer, StyledRightBottomContainer, StyledRightContainer,
    StyledRightTopContainer
} from './styles';
import { VendorImagesProps } from './types';

export default function VendorImages({
  image,
  location = 'card',
  productImages = [],
}: VendorImagesProps) {
  return (
    <StyledContainer location={location}>
      <StyledLeftContainer
        css={{
          backgroundImage: `url(${image})`,
        }}
        fullWidth={productImages.length < 2}
        location={location}
      />

      {(productImages.length || 0) > 1 && (
        <StyledRightContainer location={location}>
          <StyledRightTopContainer
            css={{
              backgroundImage: `url(${productImages[0]})`,
            }}
            location={location}
          />
          <StyledRightBottomContainer
            css={{
              backgroundImage: `url(${productImages[1]})`,
            }}
            location={location}
          />
        </StyledRightContainer>
      )}
    </StyledContainer>
  );
}
