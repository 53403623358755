import { convertDistance, getPreciseDistance } from 'geolib';
import React from 'react';

import { Text } from '../../components';
import config from '../../config';
import strings from '../../config/strings';
import { dateUtil, gtmUtil, storageUtil, stringUtil } from '../../utils';
import { isSameDay } from '../../utils/date-util';
import EventImage from './EventImage';
import { StyledCardContainer, StyledCardContent, StyledDescription, StyledLink } from './styles';

import type { GtmListEvent } from '../../types/gtm';
import type { EventCardProps } from './types';
export default function EventCard({
  data,
  gtmData,
  index,
  track = true,
}: EventCardProps) {
  let dates = dateUtil.format(data.dateBookedStart, 'MMM do ');
  if (!isSameDay(data.dateBookedStart, data.dateBookedEnd)) {
    dates += `- ${dateUtil.format(data.dateBookedEnd, 'MMM do yyyy')}`;
  } else {
    dates += dateUtil.format(data.dateBookedStart, 'yyyy');
  }

  const location = data.location;
  const userLocation = storageUtil.local.get<ItemLocation>(
    config.storageKeys.userLocation
  );
  return (
    <StyledLink
      href={stringUtil.replace(config.routes.events.details, {
        ':id': data.lotId,
      })}
      onClick={() => {
        track &&
          gtmUtil.pushEvent({
            event: 'fynd_select_event',
            data: {
              index,
              id: data.id,
              name: data.name,
              ...gtmData,
            },
            ...gtmData,
          } as GtmListEvent);
      }}
    >
      <StyledCardContainer>
        <EventImage image={data.image} />
        <StyledCardContent>
          {data.dateBookedStart ? (
            <Text type="body2">
              {dates},&nbsp;
              <span>
                {dateUtil.getTimeDisplay(data.dateBookedStart, false)}
                &nbsp;-&nbsp;
                {dateUtil.getTimeDisplay(data.dateBookedEnd, false)}&nbsp;
              </span>
            </Text>
          ) : (
            <Text type="body2">{strings.labels.noEvents}</Text>
          )}

          <Text as="h2" type="subh2">
            {data.name}
          </Text>

          <Text type="body2">
            {data.organization}
            {userLocation &&
              location.isValid &&
              !!data.organization &&
              config.unicode.centerDotPadded}
            {userLocation &&
              location.isValid &&
              `${convertDistance(
                getPreciseDistance(
                  userLocation, // TODO: Store location in root store
                  location
                ),
                'mi'
              ).toFixed(1)} mi.`}
          </Text>

          {!!data.description && (
            <StyledDescription type="body2">
              {data.description}
            </StyledDescription>
          )}
        </StyledCardContent>
      </StyledCardContainer>
    </StyledLink>
  );
}
