import Link from '../../components/Link';
import Text from '../../components/Text';
import { styled } from '../../theme/stitches.config';
import { CARD_IMAGE_DIMENSIONS } from './ProductImage/dimensions';

export const CARD_HEIGHT = 300;
export const CARD_WIDTH = CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH;

export const StyledCardContainer = styled('div', {
  borderRadius: 8,
  cursor: 'pointer',
  width: CARD_WIDTH,
  '&:hover': {
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
  '@sm': {
    width: CARD_IMAGE_DIMENSIONS.SM.CONTAINER_WIDTH,
  },
  '@md': {
    width: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
  },
  '@lg': {
    width: CARD_WIDTH,
  },
});

export const StyledCardContent = styled('div', {
  padding: 8,
  width: '100%',
  '&:hover': {
    'h2,p': {
      color: '$Neutral13',
    },
  },
});

export const StyledCardContentTop = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  '> aside': {
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      alignItems: 'flex-end',
    },
  },
});

export const StyledDescription = styled(Text, {
  truncate: 2,
  marginTop: 8,
});

export const StyledLink = styled(Link, {
  ':hover': {
    color: 'initial',
  },
});

export const StyledQuickInfo = styled('div', {
  alignItems: 'center',
  display: 'flex',
});
