import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import config from '../../config';
import strings from '../../config/strings';
import { useApp } from '../../context/app-context';
import { useLocation } from '../../context/location-context';
import { useEventApi } from '../../hooks/api';
import * as gtmUtil from '../../utils/gtm-util';

import type { QueryFunctionContext, QueryKey } from 'react-query';

import type { EventSearchResponse } from '../../services/api/types';
import type { SearchRequest } from '../../types/search';
import type { EventSearchOptions } from './types';
import type { GtmEventListView } from '../../types/gtm';
import type { SearchContextResults } from '../../context/search-context/types';

export default function useEventSearch({
  context,
  phrase,
  rows = 0,
}: EventSearchOptions): SearchContextResults<EventSearchResponse> {
  const api = useEventApi();
  const [lastRequest, updateLastRequest] = useState<SearchRequest>({
    page: 1,
    phrase,
    rows,
  });
  const currentPage = useMemo(() => lastRequest.page || 1, [lastRequest.page]);

  const { isLoggedIn = false } = useApp();

  const { position } = useLocation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<EventSearchResponse, unknown, EventSearchResponse>(
    [
      config.queryKeys.searchEvents,
      `${position?.latitude},${position?.longitude}`,
    ],
    ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const request: SearchRequest = {
        page: pageParam || 1,
        query: phrase,
        rows,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }

      updateLastRequest(request);
      return api.search(request);
    },
    {
      enabled: isLoggedIn,
      getNextPageParam: (prevResults: EventSearchResponse) =>
        prevResults.hasMore ? prevResults.page + 1 : undefined,
    }
  );

  const total = useMemo(
    () => data?.pages?.[currentPage - 1]?.total || 0,
    [currentPage, data?.pages]
  );

  useEffect(() => {
    const results = data?.pages[currentPage - 1]?.results || [];
    if (
      results.length > 0 &&
      currentPage >= 1 &&
      isFetched &&
      rows > 0 &&
      total > 0
    ) {
      if (context === 'events') {
        gtmUtil.pushEvent({
          event: 'view_search_results',
          page_location:
            window.location.pathname + (window.location.search || ''),
          search_term: phrase,
        });
      }

      const gtmData: GtmEventListView = {
        event: 'fynd_view_event_list',
        list_id: context,
        list_name: strings.labels.events,
        page: currentPage,
        list: results.map((item, index) => ({
          index,
          event_id: item.id,
          event_name: item.name,
          list_id: context,
          list_name: strings.labels.events,
        })),
      };
      gtmUtil.pushEvent(gtmData);
    }
  }, [context, currentPage, data, isFetched, phrase, rows, total]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pages: data?.pages,
    refetch,
    total,
  };
}
