import React, { useCallback, useEffect, useState } from 'react';

import { StyledButton, StyledText, StyledToggleContainer } from './styles';

import type { ToggleOptions } from './types';

export default function ToggleButtons<T extends string>({
  css,
  onChange,
  options = [],
  defaultOption,
}: ToggleOptions<T>) {
  const [value, setValue] = useState(
    options.length > 0 ? options[0].value : undefined
  );

  useEffect(() => {
    setValue(defaultOption?.value);
  }, [defaultOption]);

  const render = useCallback(
    () => (
      <StyledToggleContainer css={css}>
        {options.map((option) => (
          <StyledButton
            key={option.value}
            onClick={() => {
              setValue(option.value);
              onChange?.(option.value);
            }}
            selected={value === option.value}
          >
            <StyledText as="span" selected={value === option.value}>
              {option.label || option.value}
            </StyledText>
          </StyledButton>
        ))}
      </StyledToggleContainer>
    ),
    [css, onChange, options, value]
  );

  return render();
}
