
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/search/[[...context]]",
      function () {
        return require("private-next-pages/search/[[...context]]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/search/[[...context]]"])
      });
    }
  