export const CARD_IMAGE_DIMENSIONS = {
  CONTAINER_HEIGHT: 152,
  CONTAINER_WIDTH: 380,
  MD: {
    CONTAINER_HEIGHT: 128,
    CONTAINER_WIDTH: 336,
  },
  SM: {
    CONTAINER_HEIGHT: 128,
    CONTAINER_WIDTH: '100%',
  },
};

export const DETAILS_IMAGE_DIMENSIONS = {
  CONTAINER_HEIGHT: 400,
  CONTAINER_WIDTH: 1203,
  MD: {
    CONTAINER_HEIGHT: 240,
    CONTAINER_WIDTH: 583,
  },
  SM: {
    CONTAINER_HEIGHT: 224,
    CONTAINER_WIDTH: '100%',
  },
};
