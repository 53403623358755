import React from 'react';

import Grid from '@mui/material/Grid';

import CmsPageContent from '../../components/cms/CmsPageContent';
import { useSearch } from '../../context/search-context';
import CardsContainer from '../../context/search-context/SearchResults/CardsContainer';
import ProductCard from './ProductCard';

export function ProductResults() {
  const {
    productSearch: { isFetched, pages, total },
  } = useSearch();

  if (isFetched && !total) {
    return <CmsPageContent slug="consumer-search-no-products" />;
  }

  return (
    <CardsContainer css={{ '&.MuiGrid-root': { paddingTop: 48 } }}>
      {pages
        ?.map((page) => page.results)
        .map((items) =>
          items.map((item, index) => (
            <Grid key={item.id} item xs={12} md={6} lg={4}>
              <ProductCard data={item} index={index} />
            </Grid>
          ))
        )}
    </CardsContainer>
  );
}
