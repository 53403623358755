import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import config from '../../../config';
import strings from '../../../config/strings';
import { useLocation } from '../../../context/location-context';
import { DEFAULT_SEARCH_ROWS } from '../../../context/search-context/SearchContext';
import { useVendorApi } from '../../../hooks/api';
import { useStores } from '../../../stores';
import { dateUtil, gtmUtil } from '../../../utils';

import type { FilterMenuOption } from '../../../components/FilterMenu/types';
import type { GtmVendorListView } from '../../../types/gtm';
import type { VendorSearchResponse } from '../../../services/api/types';
import type { VendorSearchOptions } from '../types';
import type { IconName } from '@goodfynd/react-web.ui.icon';
import type { VendorScheduleRequest } from '../../../types/vendors';

export default function useOpenVendorsSearch({ phrase }: VendorSearchOptions) {
  const api = useVendorApi();
  const { position } = useLocation();
  const {
    searchStore: { display, setDisplay },
  } = useStores();

  const today = dateUtil.startOfDay(new Date());
  const dates: Date[] = [...Array(6)].map((item, index) =>
    dateUtil.addDays(today, index, true)
  );

  const dayOptions: FilterMenuOption[] = dates.map((date) => ({
    label: `${strings.labels.open} ${dateUtil.getDayDisplay(
      date,
      config.dateFormats.weekDayMonthDay
    )}`,
    value: dateUtil.format(date, config.dateFormats.yearMonthDay),
  }));

  const [selected, setSelected] = useState(
    dayOptions.length > 0 ? dayOptions[0] : undefined
  );
  const [total, setTotal] = useState(0);

  /* Fetch open vendors */
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<VendorSearchResponse, unknown>(
    [
      config.queryKeys.openVendors,
      `${position?.latitude},${position?.longitude}`,
      selected?.value,
    ],
    ({ pageParam = 1 }) => {
      const request: VendorScheduleRequest = {
        date: selected?.value,
        page: pageParam,
        query: phrase,
        rows: DEFAULT_SEARCH_ROWS,
      };

      if (Boolean(position?.latitude) && Boolean(position?.longitude)) {
        request.coordinates = `@${position?.latitude},${position?.longitude}`;
      }

      return api.getOpenVendors(request);
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.page + 1 : undefined,
      onSuccess: (data) => {
        const pageData = data.pages.at(-1);
        if (!pageData) {
          return;
        }

        const gtmData: GtmVendorListView = {
          event: 'fynd_view_vendor_list',
          list_id: 'vendors_open',
          list_name: strings.labels.vendorsOpen,
          list: pageData.results.map((item, index) => ({
            index,
            vendor_id: item.id,
            vendor_name: item.name || item.vendorName,
          })),
        };
        gtmUtil.pushEvent(gtmData);

        setTotal(pageData?.total);
      },
    }
  );

  const vendors = useMemo(
    () => data?.pages?.flatMap((page) => page.results) || [],
    [data]
  );

  const getDisplayIcon = useCallback<() => IconName>(() => {
    switch (display) {
      case 'grid':
        return 'map';

      case 'list':
        return 'grid';

      case 'map':
        return 'grid';

      default:
        return 'grid';
    }
  }, [display]);

  const toggleDisplayIcon = useCallback(() => {
    switch (display) {
      case 'grid':
        setDisplay('map');
        break;

      case 'map':
      default:
        setDisplay('grid');
        break;
    }
  }, [display, setDisplay]);

  return {
    dayOptions,
    fetchNextPage,
    getDisplayIcon,
    hasNextPage,
    isFetched,
    isFetchingNextPage,
    isLoading,
    pages: data?.pages,
    refetch,
    selected,
    setSelected,
    toggleDisplayIcon,
    total,
    vendors,
  };
}
