import React from 'react';

import { useSearch } from '../../../context/search-context';
import VendorsOpen from './VendorsOpen';

export function OpenVendorsResults() {
  const {
    display,
    vendorSearch: { openVendorsSearch },
  } = useSearch();

  const {
    dayOptions,
    getDisplayIcon,
    isFetched,
    isLoading,
    refetch,
    selected,
    setSelected,
    toggleDisplayIcon,
    total,
    vendors,
  } = openVendorsSearch;

  return (
    <VendorsOpen
      busy={isLoading}
      dayOptions={dayOptions}
      getDisplayIcon={getDisplayIcon}
      hasNoResults={isFetched && display !== 'map' && total === 0}
      refetch={refetch}
      selected={selected}
      setSelected={setSelected}
      toggleDisplayIcon={toggleDisplayIcon}
      vendors={vendors}
    />
  );
}
