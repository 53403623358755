import { observer } from 'mobx-react-lite';
import React from 'react';

import { Text } from '@goodfynd/react-web.typography.text';
import Grid from '@mui/material/Grid';

import strings from '../../config/strings';
import { useSearch } from '../../context/search-context';
import CardsContainer from '../../context/search-context/SearchResults/CardsContainer';
import { OpenVendorsResults } from './useOpenVendorsSearch/OpenVendorsResults';
import { VendorCard } from './VendorCard';

export const VendorResults = observer(() => {
  const {
    context,
    display,
    vendorSearch: { pages, total },
  } = useSearch();

  if (display === 'map') {
    return <OpenVendorsResults />;
  }

  return (
    <>
      <OpenVendorsResults />

      {Number(total) > 0 && (
        <Text as="h4" marginTop={32} type="h4">
          {strings.labels.allTrucks}
        </Text>
      )}

      <CardsContainer>
        {pages
          ?.map((page) => page.results)
          .map((items) =>
            items?.map((item, index) => (
              <Grid key={item.id} item xs={12} md={6} lg={4}>
                <VendorCard
                  data={item}
                  gtmData={{
                    list_id: context,
                    list_name: strings.labels.vendors,
                  }}
                  index={index}
                />
              </Grid>
            ))
          )}
      </CardsContainer>
    </>
  );
});
