import React, { useCallback } from 'react';

import { Button } from '@goodfynd/react-web.ui.button';
import Grid from '@mui/material/Grid';

import { useSearch } from '../';
import strings from '../../../config/strings';
import { EventResults } from '../../../hooks/useEventSearch/EventResults';
import { ProductResults } from '../../../hooks/useProductSearch/ProductResults';
import { VendorResults } from '../../../hooks/useVendorSearch/VendorResults';
import SearchLoading from './SearchLoading';
import { StyledCardsContainer, StyledLoadMoreContainer } from './styles';

export function SearchResults() {
  const { context, display, status } = useSearch();

  const renderCards = useCallback(() => {
    switch (context) {
      case 'events':
        return <EventResults />;

      case 'items':
        return <ProductResults />;

      case 'vendors':
        return <VendorResults />;
    }
  }, [context]);

  return (
    <>
      {renderCards()}

      {status.isFetching && (
        <StyledCardsContainer container>
          {[...Array(4)].map((_item, index) => (
            <Grid key={`skeleton-${index + 1}`} item xs={12} md={6} lg={4}>
              <SearchLoading />
            </Grid>
          ))}
        </StyledCardsContainer>
      )}

      {display !== 'map' && status.hasNextPage && Number(status.total) > 0 && (
        <StyledLoadMoreContainer>
          <Button
            busy={status.isFetchingNextPage}
            onClick={() => status.fetchNextPage()}
            disabled={!status.hasNextPage || status.isFetchingNextPage}
            size="md"
          >
            {strings.labels.loadMore}
          </Button>
        </StyledLoadMoreContainer>
      )}
    </>
  );
}
