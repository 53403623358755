import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { StyledStackContainer } from './styles';

import type { SearchLoadingProps } from './types';

export default function SearchLoading({
  height = 264,
  imageHeight = 152,
  width = 378,
}: SearchLoadingProps) {
  return (
    <StyledStackContainer height={height} spacing={1}>
      <Skeleton variant="rectangular" width={width} height={imageHeight} />
      <Skeleton variant="text" height={25} width={width / 2} />
      <Skeleton variant="text" width={width / 3} />
      <Skeleton variant="text" width={width / 3} />
    </StyledStackContainer>
  );
}
