import React, { useCallback, useEffect, useState } from 'react';

import { LoadingTruck } from '@goodfynd/react-web.ui.loading';

import { useIsMounted } from '../../../hooks';
import CmsApi from '../../../services/api/cms-api';
import renderCmsBodyComponent from '../../../utils/cms-util/renderCmsBodyComponent';

import type { CmsContentProps } from './types';
export default function CmsPageContent({
  containerCSS,
  render,
  slug,
}: CmsContentProps): JSX.Element {
  const isMounted = useIsMounted();
  const [body, setBody] = useState([]);
  const [busy, toggleBusy] = useState(true);

  useEffect(() => {
    if (slug) {
      (async () => {
        try {
          toggleBusy(true);
          const api = new CmsApi();
          const data = await api.getPageData(slug);
          const { body } = data?.fields || {};
          isMounted() && setBody(body);
        } finally {
          toggleBusy(false);
        }
      })();
    }
  }, [isMounted, slug]);

  const renderBody = useCallback(
    () => (
      <>
        {body?.map(({ fields, type }, index) =>
          (render || renderCmsBodyComponent)(index, type, fields, {
            containerCSS,
          })
        )}
      </>
    ),
    [body, containerCSS, render]
  );

  return busy ? <LoadingTruck /> : renderBody();
}
